import { useRouter } from 'next/router';
import { NextIntlClientProvider } from 'next-intl';
import * as fbq from '../lib/fbPixel.js';
import Script from 'next/script';

/* Types */
import type { AppProps } from 'next/app';

/* Tailwind */
import '../styles/globals.css';

/* Globals */
import '@helpers/globals';
import { useEffect } from 'react';

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    // Set SEA cookie
    const utmContent = new URL(router.asPath, process.env.NEXT_PUBLIC_BASE_URL).searchParams.get('utm_content');
    if (utmContent) {
      sessionStorage.setItem('utm_content', utmContent);
    }
  }, [ router.asPath ]);

  return (
    <>
      {
        process.env.NODE_ENV === 'production' &&
        <>
          <Script id='google-tag-manager' strategy='afterInteractive'>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${ process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER }');
            `}
          </Script>
          <Script
            async
            src={ `https://www.googletagmanager.com/gtag/js?id=${ process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS }` }
            strategy='afterInteractive'
          />
          <Script
            id='gtag'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={ {
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${ process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS }', {
              page_path: window.location.pathname,
            });
          `,
            } }
          />
          <Script
            id='fb-pixel'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={ {
              __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${ fbq.FB_PIXEL_ID });
            `,
            } }
          />
          <Script
            id='linkedIn-insightTag'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={ {
              __html: `
              _linkedin_partner_id = "5867762";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
              (function(l) {
                if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                window.lintrk.q=[]}
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);})(window.lintrk);
            `,
            } }
          />
        </>
      }
      <NextIntlClientProvider locale='nl'>
        <Component { ...pageProps } />
      </NextIntlClientProvider>
    </>
  );
}
